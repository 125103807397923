import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Select = loadable(() => import('/src/components/form-components/Select'))

const SelectYear = ({ isSubmitting, isSubmitted }) => {
    const year = new Date().getFullYear() - 18
    const yearRange = (start, stop, step) =>
        Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        )
    const years = yearRange(year, year - 62, -1)

    return (
        <Select
            name="dobYear"
            firstItem="Year"
            data={years}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        />
    )
}

SelectYear.propTypes = {
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool
}

SelectYear.defaultProps = {
    isSubmitting: false,
    isSubmitted: false
}

export default SelectYear
